<template>
  <div class="content">
    <h2>云监控</h2>
    <p class="message">
      云监控系统主要针对现有视频监控难以覆盖的内部区域和室外公共区域，通过部署支持无线WIFI的萤石、乐橙等摄像机，实现视频监控的无缝接入，实现视频监控的全方位、无死角覆盖。
    </p>
    <div class="img">
      <img src="../../../../assets/image/fbiImg/changj.png" alt="" />
      <div class="father">
        <div class="box_father_one">
          <div class="box">
            <img src="../../../../assets/image/fbiImg/chengb.png" alt="" />
            <p>成本低</p>
            <div>采用价格较低的萤石和乐 橙的设备部署成本低。</div>
          </div>
          <div class="box">
            <img src="../../../../assets/image/fbiImg/fugai.png" alt="" />
            <p>覆盖面广</p>
            <div>可以将视频监控覆盖直接 延伸至公共区域内部。</div>
          </div>
          <div class="box">
            <img src="../../../../assets/image/fbiImg/qunzong.png" alt="" />
            <p>群众接受度高</p>
            <div>与群众的安全需求相吻 合，便于推广。</div>
          </div>
        </div>
        <div class="box_father_two">
          <div class="box">
            <img src="../../../../assets/image/fbiImg/shisi.png" alt="" />
            <p>便于实施</p>
            <div>不需要专业的安装人员， 安装方便即买即用。</div>
          </div>
          <div class="box">
            <img src="../../../../assets/image/fbiImg/zhouqi.png" alt="" />
            <p>建设周期短</p>
            <div>通过统一的无线接入方 式，可快速实现海量的视 频监控接入。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.content {
  padding: 0 10%;
  margin-bottom: 50px;
  h2 {
    margin-top: 30px;
    font-size: 20px;
  }
  .message {
    font-size: 18px;
    margin-top: 20px;
    color: #6b6b6b;
  }
  .img {
    text-align: center;
  }
  .father {
    margin-top: -50px;
    .box_father_one {
      width: 100%;
      display: flex;
      justify-content: space-around;

      p {
        color: #333333;
        font-size: 20px;
      }
      div {
        margin-top: 10px;
        width: 170px;
        color: #6b6b6b;
        font-style: 16px;
      }
    }
    .box_father_two {
      margin-left: 250px;
      width: 65%;
      display: flex;
      justify-content: space-around;
      p {
        color: #333333;
        font-size: 20px;
      }
      div {
        margin-top: 10px;
        width: 170px;
        color: #6b6b6b;
        font-style: 16px;
      }
    }
  }
}
</style>
